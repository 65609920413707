<div class="widget-call-participants">
  <div
    class="widget-call-participants_closeBtn"
    (click)="closeList()"
  >
    <img src="assets/img/svg/group-lesson-next/icon-close.svg" />
  </div>
  <div class="widget-call-participants_header">Участники</div>
  <div class="widget-call-participants_find mat-form-field-infix">
    <input
      type="text"
      [(ngModel)]="filterParticipantsValue"
      (ngModelChange)="filterParticipants()"
      placeholder="Введите ФИО или id"
    />
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.90697 12.1147C0.364344 9.57204 0.364344 5.4496 2.90697 2.90697C5.44959 0.364343 9.57202 0.364343 12.1146 2.90697C14.4387 5.23103 14.6385 8.87492 12.714 11.4259L15.283 13.995L14.2776 15L11.7395 12.4619C9.18146 14.6515 5.32805 14.5357 2.90697 12.1147ZM11.1095 3.91217C9.12193 1.9247 5.89964 1.9247 3.91217 3.91217C1.92471 5.89964 1.92471 9.12194 3.91217 11.1095C5.89964 13.0969 9.12193 13.0969 11.1095 11.1095C13.0969 9.12194 13.0969 5.89964 11.1095 3.91217Z"
        fill="#92929D"
      />
    </svg>
  </div>

  <div
    class="widget-call-participants_section owner"
    *ngIf="owner"
  >
    <div class="widget-call-participants_section-content">
      <label
        class="widget-call-participants-label owner"
        for="owner"
        >Преподаватель</label
      >
      <div class="widget-call-participants_section-list">
        <ng-template *ngTemplateOutlet="userItemTemplate; context: { ctxItem: owner, isOwner: true }"></ng-template>
      </div>
    </div>
  </div>

  <div
    class="widget-call-participants_section"
    *ngIf="usersMembers?.length"
  >
    <input
      type="checkbox"
      class="accordionToogle"
      name="accordion"
      id="usersMembers"
      checked
    />
    <div class="widget-call-participants_section-content">
      <div class="widget-call-participants-members-header">
        <label
          class="widget-call-participants-label"
          for="usersMembers"
          >Слушатели(<span class="count">{{ usersMembers?.length }}</span
          >)
        </label>
        <div
          *ngIf="this.isOwner || this.isModerator"
          class="widget-call-participants-members-header_img-container"
          (click)="onChatModerateUsersChangedClick()"
        >
          <img
            *ngIf="isAnyMemberChatAccess"
            src="assets/img/svg/group-lesson-next/message-off.svg"
          />
          <img
            *ngIf="!isAnyMemberChatAccess"
            src="assets/img/svg/group-lesson-next/message-on.svg"
          />
        </div>
      </div>

      <div class="widget-call-participants_section-list">
        <ng-container *ngFor="let user of usersMembers">
          <ng-template *ngTemplateOutlet="userItemTemplate; context: { ctxItem: user, isOwner: false }"></ng-template>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template
  #userItemTemplate
  let-item="ctxItem"
  let-isOwner="isOwner"
>
  <div
    class="widget-call-participants_section-item"
    *ngIf="item.user"
  >
    <div class="member_cam-mic">
      <div
        class="member_mic-toggle"
        [class.isClick]="tryClick(item)"
        (click)="tryClick(item) && onMuteModerateUserChangedClick(item)"
      >
        <img
          *ngIf="!item.audioAccess"
          src="assets/img/svg/group-lesson-next/mic-off.svg"
        />
        <img
          *ngIf="item.audioAccess"
          src="assets/img/svg/group-lesson-next/mic-on.svg"
        />
      </div>
      <div
        class="member_cam-toggle"
        [class.isClick]="tryClick(item)"
        (click)="tryClick(item) && onVideoModerateUserChangedClick(item)"
      >
        <img
          *ngIf="!item.videoAccess"
          src="assets/img/svg/group-lesson-next/cam-off.svg"
        />
        <img
          *ngIf="item.videoAccess"
          src="assets/img/svg/group-lesson-next/cam-on.svg"
        />
      </div>
      <div
        *ngIf="!isOwner"
        class="member_moderator-toggle"
        [class.isClick]="tryClick(item)"
        (click)="tryClick(item) && onModeratorChangedClick(item)"
      >
        <img
          *ngIf="!isModeratorUserRole(item.role)"
          src="assets/img/svg/group-lesson-next/moderator-off.svg"
        />
        <img
          *ngIf="isModeratorUserRole(item.role)"
          src="assets/img/svg/group-lesson-next/moderator-on.svg"
        />
      </div>
      <div
        *ngIf="!isOwner"
        class="member_chat-toggle"
        [class.isClick]="tryClick(item)"
        (click)="tryClick(item) && onChatModerateUserChangedClick(item)"
      >
        <img
          *ngIf="!item.chatAccess"
          src="assets/img/svg/group-lesson-next/message-off.svg"
        />
        <img
          *ngIf="item.chatAccess"
          src="assets/img/svg/group-lesson-next/message-on.svg"
        />
      </div>
    </div>
    <div class="widget-call-participants_avatar">
      <div
        class="avatar-image"
        *ngIf="item.user && item.user.avatarFileName"
        [style.background]="getAvatar(item.user)"
      ></div>
      <span *ngIf="item.user">{{ item.user.firstName[0] }}{{ item.user.lastName[0] }}</span>
    </div>
    <div class="widget-call-participants_name">
      {{ item.name }} &nbsp;<span class="user-id">{{ item.user.uid }}</span>
    </div>
    <div class="widget-call-participants_right">
      <ezteach-group-lesson-participants-reactions
        [user]="item"
        [isOwner]="item.isOwner"
      >
      </ezteach-group-lesson-participants-reactions>
    </div>
    <!-- <div class="widget-call-participants_dotter"><img src="assets/img/svg/group-lesson-next/icon-dotter-16-gray.svg" /></div> -->
  </div>
</ng-template>
