import { EventEmitter, Injectable, Output } from '@angular/core';
import { ChatLessonMember, RequestChangePublishingStateWithId } from '@ezteach/api/models/chat-lesson-member';
import {
  ChatLessonMemberPublishingPermission,
  ChatLessonMemberPublishingPermissionSocket,
  ChatLessonMemberPublishingStateEnum,
  ChatLessonMemberPublishingStateSocketEnum,
  ChatLessonMemberRole,
} from '@ezteach/api/models/chat-lesson-member-permisson';
import {
  ChatLessonFieldsChangedArgs,
  ChatLessonMemberReactionArgs,
  ChatLessonModerationMemberReactionsArgs,
} from '@ezteach/api/models/lesson/chat-lesson-reaction';
import { ExtendedProlongData, LessonProlongData } from '@ezteach/api/models/prolong-data';
import { environment } from '@ezteach/enviroments';
import {
  ChatLessonMemberConnect,
  ChatLessonMemberConnectId,
  ChatLessonMemberJoin,
} from '@ezteach/group-lesson/models/chat-lesson-member-join';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { OAuthStorage } from 'angular-oauth2-oidc';

interface ChatLessonMemberRoleChanged {
  currentValue: string;
  prevValue: string;
  subjectId: number;
}

@Injectable({
  providedIn: 'root',
})
export class GroupLessonSignalrService {
  private hubConnection: HubConnection;
  private connectionUrl = environment.apiUrl + '/hub/lessons';
  lessonId: number;

  @Output()
  onChatLessonMemberPublishingPermissionChanged = new EventEmitter<ChatLessonMember[]>();
  @Output()
  onChatLessonMemberUserBanned = new EventEmitter<ChatLessonMemberRoleChanged>();
  @Output()
  OnChatLessonMemberRoleChanged = new EventEmitter<ChatLessonMemberRoleChanged>();
  @Output()
  onChatLessonMemberPublishingStateChanged = new EventEmitter<ChatLessonMember>();
  @Output()
  onMemberJoined = new EventEmitter<ChatLessonMemberJoin>();
  @Output()
  onMemberDisconnected = new EventEmitter<ChatLessonMemberConnectId>();
  @Output()
  onMemberConnected = new EventEmitter<ChatLessonMemberConnectId>();
  @Output()
  onMemberLeft = new EventEmitter<ChatLessonMemberConnect>();
  @Output()
  onLessonCanBeExtended = new EventEmitter<LessonProlongData>();
  @Output()
  onLessonExtended = new EventEmitter<ExtendedProlongData>();
  @Output()
  onConnectionEstablished = new EventEmitter();
  @Output()
  onReconnected = new EventEmitter();
  @Output()
  onChatLessonMemberReaction = new EventEmitter<ChatLessonMemberReactionArgs>();
  @Output()
  onChatLessonModerationMemberReactions = new EventEmitter<ChatLessonModerationMemberReactionsArgs>();
  @Output()
  onChatLessonFieldsChanged = new EventEmitter<ChatLessonFieldsChangedArgs>();
  @Output()
  onModeratorRequestChangePublishingState = new EventEmitter<RequestChangePublishingStateWithId>();

  constructor(private authStorage: OAuthStorage) { }

  public connect = lessonId => {
    if (this.hubConnection) {
      return;
    }
    this.lessonId = lessonId;

    this.startConnection();
    this.addListeners();
  };

  public closeHubConnection() {
    if (this.hubConnection) {
      this.hubConnection.stop().then(() => {
        this.hubConnection = null;
      });
    }
  }

  public restart() {
    this.hubConnection.stop().then(() => this.hubConnection.start());
  }

  private startConnection() {
    console.log('startConnection group');
    this.hubConnection = this.getConnection();
    console.log(this.hubConnection);
    this.hubConnection
      .start()
      .then(() => {
        console.log('GroupLessonSignalrService connectionEstablished');
        this.onConnectionEstablished.emit();
      })
      .catch(err => console.log('error while establishing signalr connection: ' + err));
    this.hubConnection.onreconnected(cId => {
      this.onReconnected.emit();
      // console.log('GroupLessonSignalrService reconnected');
    });
  }
  private getConnection(): HubConnection {
    return new HubConnectionBuilder()
      .withUrl(this.connectionUrl + '?lessonId=' + this.lessonId, {
        accessTokenFactory: () => {
          const token = this.authStorage.getItem('access_token');
          return token;
        },
      })
      .withAutomaticReconnect()
      .build();
  }

  private addListeners() {
    this.hubConnection.on('OnChatLessonMemberBatchPublishingPermissionChanged', data => {
      this.onChatLessonMemberPublishingPermissionChanged.emit(data);
    });

    this.hubConnection.on('OnChatLessonMemberRoleChanged', data => {
      if (data.currentValue === ChatLessonMemberRole.None) {
        this.onChatLessonMemberUserBanned.emit(data);
      }

      this.OnChatLessonMemberRoleChanged.emit(data);
    });

    this.hubConnection.on('OnChatLessonMemberPublishingStateChanged', data => {
      this.onChatLessonMemberPublishingStateChanged.emit(data);
    });

    // рисуем компонент, участник присоединился к занятию, включает в себя логику
    this.hubConnection.on('OnMemberJoined', data => {
      data.member.publishingPermissions = data.member.publishingPermissions.map(x => this.socketEnumToApiEnum(x));
      data.member.publishingState = data.member.publishingState.map(x => this.socketEnumToPublishingStateEnum(x));
      this.onMemberJoined.emit(data);
    });

    // удаляем в.компонет, участник покинул занятие, включает в себя логику
    this.hubConnection.on('OnMemberLeft', data => {
      this.onMemberLeft.emit(data);
    });

    // участник подключился (открыл вкладку), просто отображается что у челвоека оборвалось соединение
    this.hubConnection.on('OnMemberConnected', data => {
      console.log('OnMemberConnected', data);
      this.onMemberConnected.emit(data);
    });

    // участник отключился, пропадает компонент что участник недоступен (закрыл вкладку и не открывает, потеря соединения).
    this.hubConnection.on('OnMemberDisconnected', data => {
      console.log('OnMemberDisconnected', data);
      this.onMemberDisconnected.emit(data);
    });

    // преподавателю приходит уведомление о возможности продлить занятие
    this.hubConnection.on('onLessonCanBeExtended', (data: LessonProlongData) => {
      this.onLessonCanBeExtended.emit(data);
    });

    // приходит данные о продленном занятии
    this.hubConnection.on('onLessonExtended', (data: ExtendedProlongData) => {
      this.onLessonExtended.emit(data);
    });

    this.hubConnection.on('OnChatLessonMemberReaction', (data: ChatLessonMemberReactionArgs) => {
      this.onChatLessonMemberReaction.emit(data);
    });

    this.hubConnection.on('onChatLessonModerationMemberReactions', (data: ChatLessonModerationMemberReactionsArgs) => {
      this.onChatLessonModerationMemberReactions.emit(data);
    });

    this.hubConnection.on('onChatLessonFieldsChanged', (data: ChatLessonFieldsChangedArgs) => {
      this.onChatLessonFieldsChanged.emit(data);
    });

    this.hubConnection.on('onModeratorRequestChangePublishingState', (data: RequestChangePublishingStateWithId) => {
      this.onModeratorRequestChangePublishingState.emit(data);
    });
  }

  private socketEnumToApiEnum(value: ChatLessonMemberPublishingPermissionSocket): ChatLessonMemberPublishingPermission {
    switch (value) {
      case ChatLessonMemberPublishingPermissionSocket.Audio:
        return ChatLessonMemberPublishingPermission.Audio;
      case ChatLessonMemberPublishingPermissionSocket.Video:
        return ChatLessonMemberPublishingPermission.Video;
      case ChatLessonMemberPublishingPermissionSocket.Screen:
        return ChatLessonMemberPublishingPermission.Screen;
      case ChatLessonMemberPublishingPermissionSocket.Chat:
        return ChatLessonMemberPublishingPermission.Chat;
      default:
        throw new Error('Unexpected enum value ' + value);
    }
  }

  private socketEnumToPublishingStateEnum(
    value: ChatLessonMemberPublishingStateSocketEnum,
  ): ChatLessonMemberPublishingStateEnum {
    switch (value) {
      case ChatLessonMemberPublishingStateSocketEnum.Audio:
        return ChatLessonMemberPublishingStateEnum.Audio;
      case ChatLessonMemberPublishingStateSocketEnum.Video:
        return ChatLessonMemberPublishingStateEnum.Video;
      case ChatLessonMemberPublishingStateSocketEnum.Screen:
        return ChatLessonMemberPublishingStateEnum.Screen;
      default:
        throw new Error('Unexpected enum value ' + value);
    }
  }

  public isConnected(): boolean {
    return this.hubConnection?.state === HubConnectionState.Connected;
  }

  public callTest(data: ChatLessonMember[]) {
    // setTimeout(x => {
    //	 this.onChatLessonMemberPublishingPermissionChanged.emit(data);
    // }, 200);
  }
}
