import { Inject, Injectable } from '@angular/core';
import { LocalStorageService } from '@ezteach/_services/local-storage.service';
import { UserLangs, UserService } from '@ezteach/_services/user.service';
import { UsersService } from '@ezteach/api/services';
import { UserApiLanguagesEnum } from '@ezteach/api/services/users.service';
import { WINDOW } from '@ng-web-apis/common';

export enum Langs {
  RU = 'ru',
  KK = 'kk',
  EN = 'en',
}

@Injectable({ providedIn: 'root' })
export class LangStoreService {
  private readonly currentLangKey = 'CurrentLang';
  constructor(
    private localStorageService: LocalStorageService,
    @Inject(WINDOW) private windowRef: Window,
    private userService: UserService,
    private usersService: UsersService,
  ) { }

  setCurrentLang(lang: Langs) {
    if (!!this.userService?.userData$?.value?.language) {
      this.usersService.apiV1UsersLanguagePatch({ language: this.convertLangToNumber(lang) }).subscribe();
    }
    this.localStorageService.set(this.currentLangKey, lang);
    this.windowRef.location.reload();
  }

  getCurrentLang(): Langs {
    let lang = this.localStorageService.get(this.currentLangKey);
    if (!lang) {
      this.localStorageService.set(this.currentLangKey, Langs.RU);
      lang = this.localStorageService.get(this.currentLangKey);
    }
    let userLang: UserLangs | undefined | null = this.userService?.userData$?.value?.language;
    if (!!userLang && (lang !== this.convertUserLangToLang(userLang))) {
      this.localStorageService.set(this.currentLangKey, this.convertUserLangToLang(userLang));
      lang = this.localStorageService.get(this.currentLangKey)
      this.setCultureCookie(lang);
      this.windowRef.location.reload();
    }

    this.setCultureCookie(lang);
    return lang;
  }

  getLangCode(lang: Langs): string {
    if (lang === Langs.EN) {
      return 'en-US';
    }
    if (lang === Langs.KK) {
      return 'kk-KK';
    }
    return 'ru-RU';
  }

  setCultureCookie(lang: Langs) {
    const expireDate = new Date(2147483647 * 1000).toUTCString();
    const expires = `expires=${expireDate}`;
    const name = '.AspNetCore.Culture';
    const value = `c=${this.getLangCode(lang)}|uic=${this.getLangCode(lang)}`;
    document.cookie = `${name}=${value}; ${expires}; path=/`;
  }

  private convertUserLangToLang(userLang: UserLangs): Langs {
    let lang: Langs = Langs.RU;
    switch (userLang) {
      case UserLangs.RU:
        lang = Langs.RU;
        break;
      case UserLangs.KK:
        lang = Langs.KK;
        break;
      case UserLangs.EN:
        lang = Langs.EN;
        break;

      default:
        lang = Langs.RU;
        break;
    }
    return lang;
  }

  private convertLangToNumber(lang: Langs): UserApiLanguagesEnum {
    let langNumber: UserApiLanguagesEnum = UserApiLanguagesEnum.RU;
    switch (lang) {
      case Langs.RU:
        langNumber = UserApiLanguagesEnum.RU;
        break;
      case Langs.EN:
        langNumber = UserApiLanguagesEnum.EN;
        break;
      case Langs.KK:
        langNumber = UserApiLanguagesEnum.KK;
        break;

      default:
        langNumber = UserApiLanguagesEnum.RU;
        break;
    }
    return langNumber;
  }
}
