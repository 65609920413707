<!-- <div custom>
        <div>{{ 'Занятие окончено' | transloco }}</div>
        <div>{{ 'Вам понравилось?' | transloco }}</div>
        <div>{{ 'Репетитор' | transloco }}</div>
        <div>{{data.tutor}}</div>
        <div class="rating">
            <input
                type="radio"
                id="star5"
                name="rating"
                value="5"
                [checked]="selectedRating === 5"
                (change)="setRating(5)"
            /><label
                for="star5"
                title="5 звёзд"
            ></label>
            <input
                type="radio"
                id="star4"
                name="rating"
                value="4"
                [checked]="selectedRating === 4"
                (change)="setRating(4)"
            /><label
                for="star4"
                title="4 звезды"
            ></label>
            <input
                type="radio"
                id="star3"
                name="rating"
                value="3"
                [checked]="selectedRating === 3"
                (change)="setRating(3)"
            /><label
                for="star3"
                title="3 звезды"
            ></label>
            <input
                type="radio"
                id="star2"
                name="rating"
                value="2"
                [checked]="selectedRating === 2"
                (change)="setRating(2)"
            /><label
                for="star2"
                title="2 звезды"
            ></label>
            <input
                type="radio"
                id="star1"
                name="rating"
                value="1"
                [checked]="selectedRating === 1"
                (change)="setRating(1)"
            /><label
                for="star1"
                title="1 звезда"
            ></label>
        </div>
        <div>
            <span *ngIf="selectedRating >=1 && selectedRating <= 3">{{ 'Плохо' | transloco}}</span>
            <span *ngIf="selectedRating == 4">{{ 'Хорошо' | transloco}}</span>
            <span *ngIf="selectedRating == 5">{{ 'Отлично' | transloco}}</span>
        </div>
        <div>{{ 'Поставьте оценку от 1 до 5, где 5 - отлично' | transloco }}</div>
        <div></div>
        <ezteach-btn
            (click)="close()"
            [disabled]="selectedRating == null"
        >
            <div content>{{ 'Готово' | transloco }}</div>
        </ezteach-btn>
    </div> -->



<div
    class="container"
    custom
>
    <div class="vote-view__title">
        <h3>Занятие окончено</h3>

        <h1>Вам понравилось?</h1>

        <span
            class="close"
            (click)="close()"
        >
            <img
                src="assets/img/svg/icon-close_dark.svg"
                alt="closeButton"
            />
        </span>
    </div>

    <div class="members-wrapper">
        <div
            class="members"
            *ngIf="data.tutor"
        >
            <div class="icon">
                <svg
                    width="16"
                    height="16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M1.8 13.12A5.72 5.72 0 0 1 7.52 7.4h.96a5.72 5.72 0 0 1 5.72 5.72A1.88 1.88 0 0 1 12.32 15H3.68a1.88 1.88 0 0 1-1.88-1.88Z"
                        stroke="#678AFF"
                        stroke-width="2"
                    />
                    <circle
                        cx="8"
                        cy="4"
                        r="3"
                        stroke="#678AFF"
                        stroke-width="2"
                    />
                </svg>
            </div>
            <div class="text-title">
                {{ 'Репетитор' | transloco }}
            </div>
        </div>
        <div class="members-list">
            {{ data.tutor }}
        </div>
    </div>


    <div class="vote">
        <div class="vote-stars">
            <div
                class="vote-star"
                *ngFor="let star of stars"
                (click)="setRating(star)"
                [class.selected]="selectedRating === star"
            >
                <svg
                    width="30"
                    height="28"
                    [attr.fill]="star <= selectedRating ? '#678AFF' : 'none'"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M15.463 1.213a.5.5 0 0 0-.926 0l-3.52 8.704-9.367.658a.5.5 0 0 0-.286.882l7.19 6.037-2.268 9.111a.5.5 0 0 0 .75.545L15 22.178l7.964 4.972a.5.5 0 0 0 .75-.544l-2.268-9.112 7.19-6.037a.5.5 0 0 0-.286-.882l-9.366-.658-3.52-8.704Z"
                        stroke="#678AFF"
                        stroke-linejoin="round"
                    />
                </svg>
            </div>
        </div>

        <div class="desc">
            Поставьте оценку от 1 до 5, где 5 - отлично
        </div>

        <div class="selected-vote">
            <span *ngIf="selectedRating >=1 && selectedRating <= 3">{{ 'Плохо' | transloco}}</span>
            <span *ngIf="selectedRating == 4">{{ 'Хорошо' | transloco}}</span>
            <span *ngIf="selectedRating == 5">{{ 'Отлично' | transloco}}</span>
        </div>
    </div>


    <div class="vote-btn">
        <ezteach-btn
            [type]="buttonType.filled"
            [width]="'100%'"
            [height]="'38px'"
            (click)="submit()"
        >
            <div content>{{ 'Готово' | transloco }}</div>
        </ezteach-btn>
    </div>

</div>