<div
  class="input-container"
  [class.focused]="isFocused"
  [class.disabled]="disabled"
  [class.error]="showError && !value"
  [class.textarea]="isTextArea"
  (click)="focusInput()"
>

  <input
    class="input-field"
    *ngIf="!isTextArea"
    [disabled]="disabled"
    [attr.maxlength]="maxLength"
    [value]="value"
    #textInput
    (focus)="onFocus()"
    (blur)="onBlur()"
    (input)="onValueChange($event)"
    type="text"
  />

  <textarea
    class="input-field"
    #textInput
    *ngIf="isTextArea"
    [disabled]="disabled"
    [attr.maxlength]="maxLength"
    [value]="value"
    (focus)="onFocus()"
    (blur)="onBlur()"
    (input)="onValueChange($event)"
  ></textarea>


  <label
    class="placeholder-label"
    [class.shrunk]="isFocused || value?.length > 0"
  >
    {{ placeholder }}
  </label>

  <div
    class="error-text"
    *ngIf="showError && !value"
  >
    {{ errorText }}
  </div>

  <div
    class="char-counter"
    *ngIf="maxLength"
  >
    {{ (maxLength - value.length) >= 0 ? maxLength - value.length : 0 }}
  </div>
</div>