<div
  class="group-member-block"
  [ngClass]="{
    'one-member':
      (ownerClient && memberClients?.length === 0) || (memberClients?.length === 1 && !ownerContainerDragReleased),
    'two-columns': memberClients?.length > 1
  }"
>
  <div
    class="member owner"
    (dblclick)="onPinClicked(pinnedMember && pinnedMember !== ownerClient ? pinnedMember : ownerClient)"
  >
    <ng-container *ngIf="pinnedMember && pinnedMember !== ownerClient; else ownerElemContent">
      <ng-template *ngTemplateOutlet="memberElemContent; context: { member: pinnedMember }"></ng-template>
    </ng-container>
  </div>

  <div
    id="members"
    class="members --open"
    [class.isMobile]="isMobile"
  >
    <div
      class="members-header"
      *ngIf="memberClients?.length > 0"
      (click)="showMembers = !showMembers"
      [class.expanded]="showMembers"
    >
      <span class="members-header-text">{{ showMembers ? 'Свернуть' : 'Развернуть' }}</span>
      <div class="members-header-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M8.04492 10.8061L12.5449 8.10613C13.0449 7.80613 13.6449 8.00613 13.8449 8.50613C14.0449 9.00613 14.0449 9.60613 13.5449 9.80613L8.54492 12.8061C8.24492 13.0061 7.84492 13.0061 7.54492 12.8061L2.54492 9.80613C2.04492 9.50613 1.94492 8.90613 2.24492 8.40613C2.54492 7.90613 3.14492 7.80613 3.64492 8.10613L8.04492 10.8061Z"
            fill="#ffffff"
          />
          <path
            d="M12.5449 3.8062C13.0449 3.5062 13.6449 3.7062 13.8449 4.1062C14.0449 4.5062 14.0449 5.2062 13.5449 5.5062L8.54492 8.5062C8.24492 8.7062 7.84492 8.7062 7.54492 8.5062L2.54492 5.5062C2.04492 5.2062 1.94492 4.6062 2.24492 4.1062C2.54492 3.6062 3.14492 3.5062 3.64492 3.8062L8.04492 6.5062L12.5449 3.8062Z"
            fill="#ffffff"
          />
        </svg>
      </div>
    </div>

    <ng-container *ngIf="showMembers">
      <ng-container *ngFor="let member of memberClients">
        <div
          class="member"
          [ngClass]="{
            '--reaction': isChatOpen,
            '--pin-on': !isOwner && isMobile,
            isMobile: isMobile
          }"
          (dblclick)="onPinClicked(member === pinnedMember ? ownerClient : member)"
        >
          <ng-container
            *ngTemplateOutlet="
              member === pinnedMember ? ownerElemContent : memberElemContent;
              context: { member: member }
            "
          ></ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <ng-template
    #ownerElemContent
    let-member="member"
  >
    <div
      class="member-content"
      cdkDrag="whiteBoardEnabled || whiteBoardStarting"
      cdkDragBoundary=".container__members-owner"
      (cdkDragReleased)="ownerContainerDragReleased($event)"
      [cdkDragFreeDragPosition]="dragPosition"
      [ngClass]="{
        'default-owner': (!whiteBoardEnabled && !whiteBoardStarting) || !whiteBoardOnPinnedMode,
        'draggable-owner': (whiteBoardEnabled || whiteBoardStarting) && whiteBoardOnPinnedMode
      }"
    >
      <ezteach-ov-video-merge
        [pinnedMember]="pinnedMember"
        [hasScrollBar]="hasScrollBar"
        [isSpeech]="isSpeech"
        [view]="view"
        [memberClient]="ownerClient"
        [speakers]="speakers"
        [moderator]="true"
        [isMobile]="isMobile"
        [owner]="isOwner"
        [streamManager]="member?.stream"
        [away]="away"
        [lazyIniting]="lazyIniting"
        [isOwnerVideo]="true"
      >
        <div
          [style.display]="!away ? 'none' : ''"
          class="away-block"
        >
          <img
            src="assets/img/content/tutor-away-layout.svg"
            class="away-img"
            [ngClass]="{
              horizontal: view === viewType.horizont
            }"
          />
          <p class="msg-header">{{ 'Преподаватель отошел' | transloco }}</p>
          <p
            class="msg-description"
            [ngClass]="{
              'draggable-owner': whiteBoardEnabled || whiteBoardStarting
            }"
          >
            {{ 'Возможно, он случайно закрыл вкладку с занятием' | transloco }} ,<br />
            {{ 'не переживайте, он скоро вернется' | transloco }}
          </p>
        </div>
      </ezteach-ov-video-merge>
    </div>
    <div class="member_pin_wrap">
      <ezteach-pin-control-merge
        class="pin-control"
        [isOwnerPin]="true"
        [isOwner]="isOwner"
        [member]="member"
        [isModerator]="isModerator"
        [isSpeech]="isSpeech"
        [isPinned]="pinnedMember === ownerClient"
        [audioEnabled]="!isAudioChecked(ownerClient, membersPermission)"
        [videoEnabled]="!isVideoChecked(ownerClient, membersPermission)"
        [shareEnabled]="!isShareScreenChecked(ownerClient, membersPermission)"
        (onPinClicked)="onPinClicked(ownerClient)"
        (onMicClicked)="OnPinAudioClicked(ownerClient)"
      >
      </ezteach-pin-control-merge>
    </div>
    <div class="member_reaction">
      <ezteach-group-lesson-participants-reactions
        [user]="pinnedMember?.member"
        [lessonId]="chatLesson?.id"
        [isOwner]="true"
      >
      </ezteach-group-lesson-participants-reactions>
    </div>
    <div
      class="group-lesson-whiteboard"
      [ngClass]="{ 'with-border': whiteboardViewState !== whiteboardViewStateType.full }"
      *ngIf="whiteBoardEnabled || whiteBoardStarting"
    >
      <ezteach-app-whiteboard
        #whiteBoardComponent
        [isOwner]="isOwner"
        [whiteBoardMode]="whiteBoardMode.inside"
        [whiteBoardTopOffset]="whiteBoardTopOffset"
        (wbCreated)="onWbCreated($event)"
        (onFullSizeButtonClick)="updateWhiteboardView($event)"
      >
      </ezteach-app-whiteboard>
    </div>
  </ng-template>

  <ng-template
    #memberElemContent
    let-member="member"
  >
    <ezteach-ov-video-merge
      [moderateActionIsVisible]="(isOwner || isModerator) && !isSpeech && !isMe(member) && member !== ownerClient"
      [pinnedMember]="pinnedMember"
      [hasScrollBar]="hasScrollBar"
      [isSpeech]="isSpeech"
      [view]="view"
      [streamManager]="member.stream"
      [memberClient]="member"
      [speakers]="speakers"
      [lazyIniting]="lazyIniting"
    >
      <div
        [style.display]="(member === ownerClient ? !away : true) ? 'none' : ''"
        class="away-block"
      >
        <img
          src="assets/img/content/tutor-away-layout.svg"
          class="away-img"
          [ngClass]="{
            horizontal: view === viewType.horizont
          }"
        />
        <p class="msg-header">{{ 'Преподаватель отошел' | transloco }}</p>
        <p class="msg-description">
          {{ 'Возможно, он случайно закрыл вкладку с занятием' | transloco }},<br />
          {{ 'не переживайте, он скоро вернется' | transloco }}
        </p>
      </div>
    </ezteach-ov-video-merge>

    <ezteach-pin-control-merge
      class="pin-control"
      [isOwner]="isOwner"
      [isOwnerPin]="false"
      [member]="member"
      [isSpeech]="isSpeech"
      [isModerator]="isModerator"
      [isPinned]="pinnedMember === member"
      [audioEnabled]="!isAudioChecked(member, membersPermission)"
      [videoEnabled]="!isVideoChecked(member, membersPermission)"
      [shareEnabled]="!isShareScreenChecked(member, membersPermission)"
      (onPinClicked)="onPinClicked(member)"
      (onMicClicked)="OnPinAudioClicked(member)"
      (onVideoStatusMemberChanged)="(onVideoStatusMemberChanged)"
    >
    </ezteach-pin-control-merge>

    <div class="member_reaction">
      <ezteach-group-lesson-participants-reactions
        [user]="member.member"
        [lessonId]="chatLesson?.id"
        [isOwner]="member.member.isOwner"
      >
      </ezteach-group-lesson-participants-reactions>
    </div>

    <ng-container *ngIf="(isOwner || isModerator) && !isSpeech && !isMe(member) && member !== ownerClient">
      <div
        class="action"
        type="button"
        cdkOverlayOrigin
        #trigger="cdkOverlayOrigin"
        text="Open menu"
        (click)="openMenu(member)"
      >
        <img
          src="/assets/img/svg/icon-menu_white.svg"
          alt="moderate-menu"
        />
      </div>

      <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="isOpen(member)"
        [cdkConnectedOverlayPositions]="positions"
        [cdkConnectedOverlayHasBackdrop]="true"
        [cdkConnectedOverlayBackdropClass]="'cdk-drop-down-back'"
        (backdropClick)="backdropClick($event)"
      >
        <div
          class="action overlay"
          type="button"
          cdkOverlayOrigin
          #trigger="cdkOverlayOrigin"
          text="Open menu"
          (click)="openMenu(member)"
        >
          <img
            src="/assets/img/svg/icon-menu_white.svg"
            alt="moderate-menu"
          />
        </div>
        <div class="dropdown">
          <div class="checkbox__wrapper">
            <mat-checkbox
              [checked]="isAudioChecked(member, membersPermission)"
              (change)="audioChanged($event, member)"
            >
              {{ 'Доступ к микрофону' | transloco }}
            </mat-checkbox>
            <mat-checkbox
              [checked]="isVideoChecked(member, membersPermission)"
              (change)="videoChanged($event, member)"
            >
              {{ 'Доступ к камере' | transloco }}
            </mat-checkbox>
            <mat-checkbox
              [checked]="isShareScreenChecked(member, membersPermission)"
              (change)="shareScreenChanged($event, member)"
            >
              {{ 'Доступ к демонстрации экрана' | transloco }}
            </mat-checkbox>
          </div>
          <hr />
          <div
            class="delete__title"
            (click)="onBanned(member)"
          >
            {{ 'Удалить из звонка' | transloco }}
          </div>
          <div class="tail"></div>
        </div>
      </ng-template>
    </ng-container>
  </ng-template>
</div>
