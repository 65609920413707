<ng-container *ngIf="chatLesson; else loading">
  <div class="widget-call {{ view }}">
    <div
      class="call-wrap"
      [class.is-mobile]="isMobile"
      #groupLessonContainer
    >
      <ezteach-group-lesson-header-merge
        *ngIf="(!prolongVisible || virtualKeyboardDisplayed) && !orientationLandscape"
        [isSpeech]="isSpeech"
        [pinnedMode]="pinnedMode"
        [ownerClient]="ownerClient"
        [view]="view"
        [isMobile]="isMobile"
        [lessonLink]="lessonLink"
        [inviteLink]="inviteLink"
        [isOwner]="isOwner"
        [isChatOpen]="isChatOpen"
        [narrowScreen]="narrowScreen"
        [memberSortType]="memberSortType"
        [subject]="chatLesson.subject"
        (onMemberSort)="memberSort($event)"
        (onClickTimeDotter)="onClickTimeDotter()"
      ></ezteach-group-lesson-header-merge>
      <prolong
        [data]="prolongData"
        [chatOpen]="isChatOpen"
        [narrowScreen]="narrowScreen"
        [isMobile]="isMobile"
        [errorMessage]="errorMessage"
        *ngIf="prolongVisible && !virtualKeyboardDisplayed"
        (onLeaveSession)="leaveSession()"
        (onMinimize)="onMinimizeProlong()"
        (onExtended)="onExtendedProlong($event)"
      ></prolong>
      <ezteach-group-lesson-member-merge
        [ngClass]="{ scroll: orientationLandscape }"
        [lazyIniting]="lazyIniting"
        [pinnedMember]="pinnedMember"
        [pinnedMode]="pinnedMode"
        [view]="view"
        [isMobile]="isMobile"
        [isOwner]="isOwner"
        [isModerator]="isModerator"
        [isChatOpen]="isChatOpen"
        [speakers]="speakers"
        [memberClients]="memberClients"
        [memberSortType]="memberSortType"
        [ownerClient]="ownerClient"
        (onMuteMemberChanged)="onMuteMemberChanged($event)"
        (onVideoStatusMemberChanged)="onVideoStatusMemberChanged($event)"
        (onShareScreenStatusMemberChanged)="onShareScreenStatusMemberChanged($event)"
        [away]="isAway"
        [chatLesson]="chatLesson"
        [class.prolong]="prolongVisible && !virtualKeyboardDisplayed"
      >
      </ezteach-group-lesson-member-merge>
      <ezteach-group-lesson-toolbar-merge
        *ngIf="!prolongVisible || virtualKeyboardDisplayed"
        [shareActive]="shareActive"
        [audioEnabled]="audioEnabled"
        [videoEnabled]="videoEnabled"
        [shareDisabled]="shareDisabled"
        [canAudio]="audioPermission"
        [canVideo]="videoPermission"
        [canShare]="sharePermission"
        [unreadMessageCounter]="unreadMessageCounter"
        [isMobile]="isMobile"
        [isOwner]="isOwner"
        [lessonLink]="lessonLink"
        [inviteLink]="inviteLink"
        [userTotalCount]="userTotalCount"
        [publisherStateChangingValue]="publisherStateChangingValue"
        [lazyIniting]="lazyIniting"
        [topPositionOffsetByChat]="topPositionToolbarOffsetByChat"
        [ownerName]="ownerClient?.member?.name"
        [isStarted]="isStarted"
        (onLeaveSession)="leaveSession()"
        (onStartShare)="startShare()"
        (onStopShare)="stopShare()"
        (onAudioStatusChanged)="audioStatusChanged()"
        (onVideoStatusChanged)="videoStatusChanged()"
        (onStartCall)="onStartCall()"
        (onMuteChanged)="onMuteChangedClick($event)"
        (onHandOff)="onHandOff()"
        (onHandOn)="onHandOn()"
        (onVideoMembersStatusChanged)="onVideoMembersStatusChangedClick($event)"
        [idLesson]="chatLesson.id"
        [homeWorkTemplate]="chatLesson?.homeWorkTemplate"
      ></ezteach-group-lesson-toolbar-merge>
    </div>
    <div
      class="call-sidebar --video-on"
      [ngClass]="{
        '--chatIsOpen': groupLessonService.chatOpen$.value,
        '--groupIsOpen': groupLessonService.parcipantsOpen$.value
      }"
    >
      <div class="call-sidebar-video-wrap">
        <ezteach-ov-video-merge
          [moderateActionIsVisible]="isOwner || isModerator"
          [pinnedMember]="pinnedMember"
          [hasScrollBar]="false"
          [isSpeech]="isSpeech"
          [view]="view"
          [memberClient]="ownerClient"
          [speakers]="speakers"
          [lazyIniting]="lazyIniting"
        >
        </ezteach-ov-video-merge>
      </div>
      <ezteach-group-lesson-participants-merge
        [isOwner]="isOwner"
        [isModerator]="isModerator"
        [chatLessonId]="chatLesson.id"
      ></ezteach-group-lesson-participants-merge>
      <ezteach-group-lesson-chat-merge
        class="widget-call-chat"
        [chatRoomId]="chatLesson.chatRoomId"
        [isMobile]="isMobile"
        [chatIsOpen]="groupLessonService.chatOpen$.value"
        [orientationLandscape]="orientationLandscape"
        [isSendMessage]="isSendMessage"
      ></ezteach-group-lesson-chat-merge>
    </div>
  </div>
</ng-container>

<ng-template #loading> {{ 'загрузка занятия' | transloco }} </ng-template>

<div
  *ngIf="reconnecting"
  class="group-lesson-networkerror"
  [ngClass]="{
    'group-lesson-networkerror-fullMode': fullViewPortGroupLessonService.isStateFullMode
  }"
>
  <div class="group-lesson-networkerror__loader">
    <mat-spinner
      diameter="20"
      class="loader"
    ></mat-spinner>
  </div>
  <div class="group-lesson-networkerror__sts">
    {{ 'Отсутствует подключение к Интернету. Подключение...' | transloco }}
  </div>
</div>

<div
  *ngIf="isIOS"
  style="position: fixed; top: -1000px"
>
  <audio
    #audio
    loop
    autoplay
    src="assets/audio/empty.mp3"
  ></audio>
</div>
