import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { coerceBooleanProperty } from './utils';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
  host: {
    '(dragover)': 'onDragOver($event)',
    '(dragleave)': 'onDragLeave($event)',
    '(drop)': 'onDrop($event)',
    '(paste)': 'onPaste($event)'
  }
})
export class FileInputComponent {
  private _multiple = false;
  private isHandlingDrop = false;

  @ViewChild('fileInput') _inputElement: ElementRef;
  get inputElement(): HTMLInputElement {
    return this._inputElement.nativeElement;
  }
  @Input() disabled: boolean;
  @Input()
  set multiple(multiple: boolean) {
    this._multiple = coerceBooleanProperty(multiple);
  }
  get multiple(): boolean {
    return this._multiple;
  }
  @Input() accept: string;
  @Input() dropZone: any;
  @Output() select: EventEmitter<File | FileList> = new EventEmitter<File | FileList>();

  private dropZoneId = "group-lesson-chat-drop-zone";
  constructor(private _renderer: Renderer2, _changeDetectorRef: ChangeDetectorRef) { }

  ngAfterViewInit() {
    if (this.dropZone) {
      this.dropZone.setAttribute('data-dropzone-id', this.dropZoneId);
      this.dropZone.addEventListener('dragover', this.onDragOver.bind(this));
      this.dropZone.addEventListener('dragleave', this.onDragLeave.bind(this));
      this.dropZone.addEventListener('drop', this.onDrop.bind(this));
      this.dropZone.addEventListener('paste', this.onPaste.bind(this));
    }
  }

  hasFiles(dataTransfer: DataTransfer): boolean {
    if (dataTransfer && dataTransfer.items) {
      for (let i = 0; i < dataTransfer.items.length; i++) {
        if (dataTransfer.items[i].kind === 'file') {
          return true;
        }
      }
    }
    return false;
  }

  onPaste(event: ClipboardEvent) {
    const clipboardItems = event.clipboardData?.items;
    if (clipboardItems) {
      for (let i = 0; i < clipboardItems.length; i++) {
        if (clipboardItems[i].kind === 'file') {
          const file = clipboardItems[i].getAsFile();
          if (file) {
            this.handleSelect(file);
            event.preventDefault();
            break;
          }
        }
      }
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    const currentTarget = event.currentTarget as HTMLElement;
    if (!currentTarget.classList.contains('drag-over') && this.hasFiles(event.dataTransfer)) {
      currentTarget.classList.add('drag-over');
    }
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    const relatedTarget = event.relatedTarget as HTMLElement;
    const currentTarget = event.currentTarget as HTMLElement;
    if (!currentTarget.contains(relatedTarget)) {
      if (currentTarget.classList.contains('drag-over')) {
        currentTarget.classList.remove('drag-over');
      }
    }
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    const currentTarget = event.currentTarget as HTMLElement;
    if (currentTarget.classList.contains('drag-over')) {
      currentTarget.classList.remove('drag-over');
    }
    if (!this.isHandlingDrop && event.dataTransfer.files.length > 0) {
      this.isHandlingDrop = true;
      this.handleSelect(event.dataTransfer.files[0]);

      setTimeout(() => {
        this.isHandlingDrop = false;
      }, 100);
    }
  }

  handleSelect(files: File | FileList): void {
    this.select.emit(files);
    this.clear();
  }
  clear(): void {
    this._renderer.setProperty(this.inputElement, 'value', '');
  }
  onDisabledChange(v: boolean): void {
    if (v) {
      this.clear();
    }
  }
}
