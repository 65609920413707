import { ConnectionPositionPair, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { RequestChangePublishingStateWithId } from '@ezteach/api/models/chat-lesson-member';
import {
  ChatStatusMember,
  ChatStatusMembers,
  MuteMember,
  ShareScreenStatusMember,
  VideoStatusMember
} from '@ezteach/group-lesson/components/group-lesson-owner/group-lesson-owner.component';
import { GroupLessonParticipantsListComponent } from '@ezteach/group-lesson/components/group-lesson-participants-list/group-lesson-participants-list.component';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GroupLessonService } from '../group-lesson.service';

interface groupLessonParticipantsConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  onlyHandsOn?: boolean;
}

const DEFAULT_CONFIG: groupLessonParticipantsConfig = {
  hasBackdrop: true,
  backdropClass: 'cdk-overlay-dark-backdrop',
  panelClass: '',
  onlyHandsOn: false,
};

@Injectable({
  providedIn: 'root',
})
export class GroupLessonParticipantsOverlayService {
  overlayRef: OverlayRef;
  isMobile: boolean;
  isOpened = new BehaviorSubject<boolean>(false);
  onModerateMuteChanged = new Subject<string>();
  onModerateVideoStatusChanged = new Subject<string>();
  onModerateShareScreenChanged = new Subject<string>();
  onModerateUserMuteChanged = new Subject<MuteMember>();
  onModerateUserVideoStatusChanged = new Subject<VideoStatusMember>();
  onModerateUserShareScreenChanged = new Subject<ShareScreenStatusMember>();
  onModerateUserChatStatusChanged = new Subject<ChatStatusMember>();
  onModerateUsersChatStatusChanged = new Subject<ChatStatusMembers>();
  readonly onRequestMediaStateChange$ = new Subject<RequestChangePublishingStateWithId>();
  componentSubscriptions: Subscription[] = [];

  constructor(private overlay: Overlay, private groupLessonService: GroupLessonService) {
    this.groupLessonService.lessonIsDestroyed
      .pipe(
        tap(x => {
          if (x && this.overlayRef) {
            this.overlayRef.detach();
            this.componentSubscriptionsUnsubscribe();
          }
        }),
      )
      .subscribe();
  }

  open(
    origin,
    isMobile = false,
    onlyHands = false,
    isOwner = false,
    isSpeech = false,
    config: groupLessonParticipantsConfig = {},
  ) {
    // Перезаписываем default конфигурацию
    const dialogConfig = { ...DEFAULT_CONFIG, ...config };
    this.isMobile = isMobile;

    this.overlayRef = this.createOverlay(origin, onlyHands, dialogConfig);

    const groupLessonParticipantsPortal = new ComponentPortal(GroupLessonParticipantsListComponent);

    const componentRef = this.overlayRef.attach(groupLessonParticipantsPortal);
    const component = componentRef.instance as GroupLessonParticipantsListComponent;
    component.setIsSpeech(isSpeech);
    if (onlyHands) {
      component.showOnlyHandsUp();
    }
    if (isOwner) {
      component.setIsOwner();
    }

    this.overlayRef.backdropClick().subscribe(() => this.close());
    this.isOpened.next(true);
    const requestMediaSubscription = component.requestMediaStateChange.subscribe(v => {
      this.onRequestMediaStateChange$.next(v);
    });
    this.componentSubscriptions.push(requestMediaSubscription);
  }

  private createOverlay(origin, onlyHands, config: groupLessonParticipantsConfig) {
    // Возвращаем OverlayConfig
    const overlayConfig = this.getOverlayConfig(origin, onlyHands, config);

    return this.overlay.create(overlayConfig);
  }

  private getOverlayConfig(origin, onlyHands, config: groupLessonParticipantsConfig): OverlayConfig {
    let positionStrategy;
    if (this.isMobile) {
      positionStrategy = this.overlay.position().global().centerHorizontally().bottom();
    } else {
      positionStrategy = this.overlay
        .position()
        .flexibleConnectedTo(origin)
        .withPositions(this.getPositions(onlyHands));
    }

    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy,
    });
    return overlayConfig;
  }

  private getPositions(onlyHands: boolean): ConnectionPositionPair[] {
    if (onlyHands) {
      return [
        new ConnectionPositionPair({ originX: 'end', originY: 'bottom' }, { overlayX: 'end', overlayY: 'top' }, 0, 10),
      ];
    }
    return [
      new ConnectionPositionPair(
        { originX: 'start', originY: 'top' },
        { overlayX: 'start', overlayY: 'bottom' },
        0,
        -10,
      ),
    ];
  }

  close() {
    this.overlayRef.dispose();
    this.componentSubscriptionsUnsubscribe();
    this.isOpened.next(false);
  }

  componentSubscriptionsUnsubscribe(): void {
    this.componentSubscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
    this.componentSubscriptions = [];
  }
}
