import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ChatLesson } from '@ezteach/api/models/chat-lesson';
import { ChatLessonMember } from '@ezteach/api/models/chat-lesson-member';
import {
  ChatLessonMemberPublishingPermission,
  ChatLessonMemberRole,
} from '@ezteach/api/models/chat-lesson-member-permisson';
import { LessonsService } from '@ezteach/api/services/lessons.service';
import { getStreamData } from '@ezteach/group-lesson/helpers/stream.data';
import { StreamUserData } from '@ezteach/group-lesson/models/stream-user-data';
import { GroupLessonPermissionService } from '@ezteach/group-lesson/services/group-lesson-permisson.service/group-lesson-permisson.service';
import { OpenViduService } from '@ezteach/group-lesson/services/open-vidu.service';
import { tuiPure } from '@taiga-ui/cdk/decorators';
import { StreamManager } from 'openvidu-browser';

export interface MuteMember {
  userData: StreamUserData;
  audioEnabled: boolean;
}

export interface VideoStatusMember {
  userData: StreamUserData;
  videoEnabled: boolean;
}

export interface ShareScreenStatusMember {
  userData: StreamUserData;
  shareScreenEnabled: boolean;
}

export interface ChatStatusMember {
  userData: StreamUserData;
  chatEnabled: boolean;
}

export interface ChatStatusMembers {
  userData: StreamUserData[];
  chatEnabled: boolean;
}

@Component({
  selector: 'ezteach-group-lesson-owner',
  templateUrl: './group-lesson-owner.component.html',
  styleUrls: ['./group-lesson-owner.component.scss'],
})
export class GroupLessonOwnerMergeComponent implements OnInit {
  membersPermission: ChatLessonMember[] = [];
  openedMember = null;

  @Input() streamManager: StreamManager;
  @Input() members: StreamManager[];
  @Input() speakers: string[];
  @Input() chatLesson: ChatLesson;

  @Output() onMuteMemberChanged = new EventEmitter<MuteMember>();
  @Output() onVideoStatusMemberChanged = new EventEmitter<VideoStatusMember>();
  @Input() isMobile: boolean;
  @Input() isChatOpen: boolean;

  positions = [
    new ConnectionPositionPair({ originX: 'start', originY: 'top' }, { overlayX: 'end', overlayY: 'bottom' }, 40, -6),
  ];

  constructor(
    private groupLessonPermissionService: GroupLessonPermissionService,
    private lessonsService: LessonsService,
    private openViduService: OpenViduService,
  ) {
    this.groupLessonPermissionService.allLessonMembers$.subscribe(x => {
      this.membersPermission = x;
      console.log('membersPermission', this.membersPermission);
    });
  }

  ngOnInit() {
    this.isAudioChecked(this.streamManager, this.membersPermission);
    this.isVideoChecked(this.streamManager, this.membersPermission);
  }

  openMenu(member) {
    if (this.openedMember === member) {
      this.backdropClick(null);
    } else {
      this.backdropClick(null);
      this.openedMember = member;
    }
  }

  isOpen(member) {
    return member === this.openedMember;
  }

  backdropClick($event) {
    this.openedMember = null;
  }

  @tuiPure
  isAudioChecked(member, membersPermission) {
    if (member) {
      const data = getStreamData(member);
      const mem = membersPermission.find(x => x.user.id === +data.userid);
      if (mem) {
        return mem.publishingPermissions.indexOf(ChatLessonMemberPublishingPermission.Audio) !== -1;
      }
      return true;
    }
    return false;
  }

  @tuiPure
  isVideoChecked(member, membersPermission) {
    if (member) {
      const data = getStreamData(member);
      const mem = membersPermission.find(x => x.user.id === +data.userid);
      if (mem) {
        return mem.publishingPermissions.indexOf(ChatLessonMemberPublishingPermission.Video) !== -1;
      }
      return true;
    }
    return false;
  }

  audioChanged($event, member) {
    const data = getStreamData(member);
    this.onMuteMemberChanged.emit({
      userData: data,
      audioEnabled: $event.checked,
    });
  }

  videoChanged($event: MatCheckboxChange, member: StreamManager) {
    const data = getStreamData(member);
    this.onVideoStatusMemberChanged.emit({
      userData: data,
      videoEnabled: $event.checked,
    });
  }

  onBanned(member: StreamManager) {
    const data = getStreamData(member);
    const lessonId = this.chatLesson.id;
    const params = {
      lessonId,
      userId: data.userid,
      newRole: ChatLessonMemberRole.None,
    };
    this.lessonsService.chatLessonMemberRoleChanged(params).subscribe();
    this.openViduService.banUser(member);
  }
}
