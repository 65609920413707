import { Injectable, OnDestroy } from '@angular/core';
import { ChatRequest } from '@ezteach/api/models';
import { BehaviorSubject, Subject, Subscription, merge } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LocalStorageService } from '../_services/local-storage.service';
import { SignalrService } from '../_services/signalr.service';
import { LessonsService } from '../api/services/lessons.service';

@Injectable({
  providedIn: 'root',
})
export class RequestsService implements OnDestroy {
  readonly onChanged = new Subject<Boolean>();
  private readonly update$ = new Subject<void>();
  private readonly subscription = new Subscription();
  public sending$ = new BehaviorSubject(false);
  constructor(
    private readonly lessonsService: LessonsService,
    private readonly signalRService: SignalrService,
    private readonly localStorageService: LocalStorageService,
  ) { }

  init() {
    this.subscription.add(
      merge(
        this.update$,
        this.signalRService.onChatRequestCreated,
        this.signalRService.onChatRequestCanceled,
        this.signalRService.onChatRequestAccepted,
      )
        .pipe(switchMap(() => this.lessonsService.apiV1LessonsInstantRequestsPendingGet({})))
        .subscribe(response => {
          const requests = response.data?.filter(request =>
            !!request.createDate?.length &&
            new Date(request.createDate as string)?.getTime() + (request.expiresIn ?? 0) * 1000 - new Date().getTime() > 0
          );
          this.localStorageService.set('requests', requests);
          this.onChanged.next(true);
        }),
    );
  }

  update() {
    this.update$.next();
  }

  onChatRequestCanceled(request: ChatRequest) {
    this.signalRService.onChatRequestCanceled.next({
      requestId: request?.id,
      initiatorId: request?.initiator?.id,
      calleeId: request?.callee?.id,
      expired: true
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
