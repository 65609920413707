import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ApiConfiguration } from '../api-configuration';
import { BaseService } from '../base-service';
import { BaseApiResponse } from '../models/base-api-response';
import { Int32IEnumerableApiResponse } from '../models/int-32-i-enumerable-api-response';
import { UserNotificationPagedApiResponse } from '../models/user-notification-paged-api-response';
import { RequestBuilder } from '../request-builder';
import { StrictHttpResponse } from '../strict-http-response';

export enum UserNotificationTypeEnum {
  ScheduledLessonCreated = 1,
  ScheduledLessonUpdated = 2,
  ScheduledLessonCanceled = 3,
  ScheduledLessonAccepted = 4,
  ScheduledLessonRemind = 5
}

@Injectable({
  providedIn: 'root',
})

export class NotificationsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation apiV1NotificationsNotificationIdDelete
   */
  static readonly ApiV1NotificationsNotificationIdDeletePath = '/api/v1/notifications/{notificationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1NotificationsNotificationIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1NotificationsNotificationIdDelete$Response(params: {
    notificationId: number;
  }): Observable<StrictHttpResponse<BaseApiResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      NotificationsService.ApiV1NotificationsNotificationIdDeletePath,
      'delete',
    );
    if (params) {
      rb.path('notificationId', params.notificationId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<BaseApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1NotificationsNotificationIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1NotificationsNotificationIdDelete(params: { notificationId: number }): Observable<BaseApiResponse> {
    return this.apiV1NotificationsNotificationIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<BaseApiResponse>) => r.body as BaseApiResponse),
    );
  }

  /**
   * Path part for operation apiV1NotificationsNotificationIdViewedPatch
   */
  static readonly ApiV1NotificationsNotificationIdViewedPatchPath = '/api/v1/notifications/{notificationId}/viewed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1NotificationsNotificationIdViewedPatch()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1NotificationsNotificationIdViewedPatch$Response(params: {
    notificationId: number;
  }): Observable<StrictHttpResponse<Int32IEnumerableApiResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      NotificationsService.ApiV1NotificationsNotificationIdViewedPatchPath,
      'patch',
    );
    if (params) {
      rb.path('notificationId', params.notificationId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<Int32IEnumerableApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1NotificationsNotificationIdViewedPatch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1NotificationsNotificationIdViewedPatch(params: {
    notificationId: number;
  }): Observable<Int32IEnumerableApiResponse> {
    return this.apiV1NotificationsNotificationIdViewedPatch$Response(params).pipe(
      map((r: StrictHttpResponse<Int32IEnumerableApiResponse>) => r.body as Int32IEnumerableApiResponse),
    );
  }

  /**
   * Path part for operation apiV1NotificationsNotificationIdArchivedPatch
   */
  static readonly ApiV1NotificationsNotificationIdArchivedPatchPath = '/api/v1/notifications/{notificationId}/archived';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1NotificationsNotificationIdArchivedPatch()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1NotificationsNotificationIdArchivedPatch$Response(params: {
    notificationId: number;
  }): Observable<StrictHttpResponse<Int32IEnumerableApiResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      NotificationsService.ApiV1NotificationsNotificationIdArchivedPatchPath,
      'patch',
    );
    if (params) {
      rb.path('notificationId', params.notificationId, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<Int32IEnumerableApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1NotificationsNotificationIdArchivedPatch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1NotificationsNotificationIdArchivedPatch(params: {
    notificationId: number;
  }): Observable<Int32IEnumerableApiResponse> {
    return this.apiV1NotificationsNotificationIdArchivedPatch$Response(params).pipe(
      map((r: StrictHttpResponse<Int32IEnumerableApiResponse>) => r.body as Int32IEnumerableApiResponse),
    );
  }

  /**
   * Path part for operation apiV1NotificationsNewGet
   */
  static readonly ApiV1NotificationsNewGetPath = '/api/v1/notifications/new';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1NotificationsNewGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1NotificationsNewGet$Response(params?: {}): Observable<StrictHttpResponse<Int32IEnumerableApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, NotificationsService.ApiV1NotificationsNewGetPath, 'get');
    if (params) {
    }
    rb.query('excludeNotificationTypeIds', [UserNotificationTypeEnum.ScheduledLessonRemind], {});
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<Int32IEnumerableApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1NotificationsNewGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1NotificationsNewGet(params?: {}): Observable<Int32IEnumerableApiResponse> {
    return this.apiV1NotificationsNewGet$Response(params).pipe(
      map((r: StrictHttpResponse<Int32IEnumerableApiResponse>) => r.body as Int32IEnumerableApiResponse),
    );
  }

  /**
   * Path part for operation apiV1NotificationsGet
   */
  static readonly ApiV1NotificationsGetPath = '/api/v1/notifications';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1NotificationsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1NotificationsGet$Response(params?: {
    PageNumber?: number;
    PageSize?: number;
    statusGroupId?;
  }): Observable<StrictHttpResponse<UserNotificationPagedApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, NotificationsService.ApiV1NotificationsGetPath, 'get');
    if (params) {
      rb.query('PageNumber', params.PageNumber, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('statusGroupId', params.statusGroupId, {});
    }

    rb.query('excludeNotificationTypeIds', [UserNotificationTypeEnum.ScheduledLessonRemind], {});
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<UserNotificationPagedApiResponse>),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1NotificationsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1NotificationsGet(params?: {
    PageNumber?: number;
    PageSize?: number;
    statusGroupId?;
  }): Observable<UserNotificationPagedApiResponse> {
    return this.apiV1NotificationsGet$Response(params).pipe(
      map((r: StrictHttpResponse<UserNotificationPagedApiResponse>) => r.body as UserNotificationPagedApiResponse),
    );
  }
}
