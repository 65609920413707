import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss']
})
export class InputFieldComponent {
  private _value: string = '';
  @ViewChild('textInput') textInput: ElementRef<HTMLElement>;

  @Input() placeholder: string = 'Введите текст';
  @Input() maxLength?: number;
  @Input() disabled: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() showError = false;
  @Input() errorText: string = "Поле обязательно для заполнения";

  @Input() isTextArea: boolean = false;

  @Output() valueChanged = new EventEmitter<string>();

  isFocused = false;

  @Input()
  get value(): string {
    return this._value;
  }

  set value(val: string | null | undefined) {
    this._value = val ?? '';
  }

  constructor() { }

  onValueChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.value = target.value;

    this.valueChanged.emit(this.value)
  }

  onFocus(): void {
    this.isFocused = true;
  }

  focusInput() {
    this.textInput.nativeElement.focus()
  }

  onBlur(): void {
    this.isFocused = false;

    if (this.isRequired && !this.value) {
      this.showError = true;
    }
  }
}