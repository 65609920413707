import { DragDropModule } from '@angular/cdk/drag-drop';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeKk from '@angular/common/locales/kk';
import localeRu from '@angular/common/locales/ru';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { BreadcrumbsComponent } from '@ezteach/_components/breadcrumbs/breadcrumbs.component';
import { ButtonModule } from '@ezteach/_components/buttons/button.module';
import { PopupModule } from '@ezteach/_components/popups/popup.module';
import { ProfileWarningComponent } from '@ezteach/_components/profile-warning/profile-warning.component';
import { BrowserService } from '@ezteach/_services/browser.service';
import { environment } from '@ezteach/enviroments';
import { ModalDisableMediaByModerator } from '@ezteach/modals/disable-media-by-moderator/modal-disable-media-by-moderator';
import { ModalGroupLessonFinish } from '@ezteach/modals/group-lesson-finish/modal-group-lesson-finish.component';
import { ModalIEWarningComponent } from '@ezteach/modals/ie-warning/modal-ie-warning.component';
import { ModalLogoutConfirm } from '@ezteach/modals/logout-confirm/modal-logout-confirm.component';
import { ModalLessonMediaAccess } from '@ezteach/modals/media-access/modal-lesson-media-access.component';
import { ModalLessonMediaConstraints } from '@ezteach/modals/media-constraints/modal-lesson-media-constraints.component';
import { ModalLessonMediaInUse } from '@ezteach/modals/media-in-use/modal-lesson-media-in-use.component';
import { ModalRequestMediaStateChange } from '@ezteach/modals/request-media-state-change/modal-request-media-state-change';
import { ModalScreenSharingAccess } from '@ezteach/modals/screen-sharing/modal-screen-sharing-access.component';
import { AdvertisementWizardComponent } from '@ezteach/widgets/advertisement/advertisment-wizard/advertisement-wizard/advertisement-wizard.component';
import * as Sentry from '@sentry/angular';
import { TuiAxesModule, TuiBarChartModule, TuiBarModule } from '@taiga-ui/addon-charts';
import { TuiRootModule, TuiScrollbarModule } from '@taiga-ui/core';
import { OAuthModule, OAuthStorage, UrlHelperService } from 'angular-oauth2-oidc';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMaskModule } from 'ngx-mask';
import { FooterComponent } from './_components/footer/footer.component';
import { HeaderComponent } from './_components/header/header.component';
import { ProfileMenuModule } from './_components/header/profile-menu/profile-menu.module';
import { LessonItemModule } from './_components/lesson_item/lesson_item.module';
import { ProlongModule } from './_components/prolong/prolong.module';
import { TutorUserSearchResultComponent } from './_components/tutor-user-search-result/tutor-user-search-result.component';
import { AppLayoutComponent } from './_layout/app-layout/app-layout.component';
import { ApiModule } from './api/api.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BookmarksComponent } from './bookmarks/bookmarks.component';
import { CalendarModule } from './calendar';
import { CoreModule } from './core';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FinancesComponent } from './finances/finances.component';
import { FormSearchInstantComponent } from './forms/search/instant.component';
import { FormSearchRegularComponent } from './forms/search/regular.component';
import { GroupLessonEmbeddedWindowComponent } from './group-lesson/components/group-lesson-embedded-window/group-lesson-embedded-window.component';
import { GroupLessonMergeModule } from './group-lesson/group-lesson.module';
import { LessonsComponent } from './lessons/lessons.component';
import { MessagesListComponent } from './messages/list/messages_list.component';
import { MessagesComponent } from './messages/messages.component';
import { MessagesTopComponent } from './messages/top/messages_top.component';
import { BankCardWarningDialogComponent } from './modals/bank-card-warning-dialog/bank-card-warning-dialog.component';
import { ModalBankcardsRemove } from './modals/bankcards-remove/modal-bankcards-remove.component';
import { ModalBankcards } from './modals/bankcards/modal-bankcards.component';
import { BookCheckoutComponent } from './modals/book-checkout/book-checkout.component';
import { ModalBook } from './modals/book/modal-book.component';
import { FeedbackComponent } from './modals/feedback/feedback.component';
import { ModalInstantRequestCancel } from './modals/instant-request-cancel/modal-instant-request-cancel.component';
import { ModalInstantRequest } from './modals/instant-request/modal-instant-request.component';
import { ModalLessonCancel } from './modals/lesson-cancel/modal-lesson-cancel.component';
import { ModalLessonDone } from './modals/lesson-done/modal-lesson-done.component';
import { ModalLessonFinish } from './modals/lesson-finish/modal-lesson-finish.component';
import { ModalLessonFinished } from './modals/lesson-finished/modal-lesson-finished.component';
import { ModalLessonRating } from './modals/lesson-rating/modal-lesson-rating.component';
import { ModalMessagesFiles } from './modals/messages-files/modal-messages-files.component';
import { ModalMessagesMembers } from './modals/messages-members/modal-messages-members.component';
import { ModalSimpleError } from './modals/modal-simple-error/modal-simple-error.component';
import { ModalReclamation } from './modals/reclamation/modal-reclamation.component';
import { ModalUnavailableCamera } from './modals/unavailable-camera/unavailable-camera.component';
import { MyLessonsModule } from './my-lessons/my-lessons.module';
import { NavComponent } from './nav/nav.component';
import { NotificationsModule } from './notifications/notifications.module';
import { OnlineComponent } from './online/online.component';
import { ProfileComponent } from './profile-old/profile.component';
import { RequestsStudentItemComponent } from './requests-student/item/requests-student-item.component';
import { RequestsStudentComponent } from './requests-student/requests-student.component';
import { RequestsTutorItemComponent } from './requests-tutor/item/requests-tutor-item.component';
import { RequestsTutorComponent } from './requests-tutor/requests-tutor.component';
import { SearchInstantComponent } from './search-instant/search-instant.component';
import { SearchRegularComponent } from './search-regular/search-regular.component';
import { SettingsComponent } from './settings/settings.component';
import { SharedModule } from './shared';
import { TranslateDateAdapter } from './shared/cdk/translat-date-adapter';
import { LangSwitcherModule } from './shared/components/lang-switcher/lang-switcher.module';
import { ConsoleLogSpyService } from './shared/services/console-log.spy.service';
import { LangStoreService } from './shared/services/lang-store.service';
import { StudentComponent } from './student/student.component';
import { TeacherComponent } from './teacher/teacher.component';
import { TeachersComponent } from './teachers/teachers.component';
import { ToastComponent } from './toast/toast.component';
import { TranslocoRootModule } from './transloco-root.module';
import { TutorialModule } from './tutorial';
import { AdvertisementComponent } from './widgets/advertisement/advertisement.component';
import { WidgetBookmarksComponent } from './widgets/bookmarks/bookmarks.component';
import { FinanceGeneralComponent } from './widgets/finance/finance-general/finance-general.component';
import { FinanceHistoryComponent } from './widgets/finance/finance-history/finance-history.component';
import { WidgetFinanceComponent } from './widgets/finance/finance.component';
import { WidgetHistoryComponent } from './widgets/history/history.component';
import { WizardComponent } from './wizard/wizard.component';

registerLocaleData(localeKk);
registerLocaleData(localeRu);

@NgModule({
  declarations: [
    AppComponent,
    AppLayoutComponent,
    HeaderComponent,
    FooterComponent,
    NavComponent,
    BreadcrumbsComponent,
    FormSearchInstantComponent,
    FormSearchRegularComponent,
    TeacherComponent,
    StudentComponent,
    TeachersComponent,
    RequestsTutorComponent,
    RequestsTutorItemComponent,
    RequestsStudentComponent,
    RequestsStudentItemComponent,
    MessagesComponent,
    MessagesTopComponent,
    MessagesListComponent,
    BookmarksComponent,
    LessonsComponent,
    WidgetBookmarksComponent,
    WidgetHistoryComponent,
    WidgetFinanceComponent,
    SearchInstantComponent,
    SearchRegularComponent,
    OnlineComponent,
    SettingsComponent,
    FinancesComponent,
    ProfileComponent,
    WizardComponent,
    ToastComponent,
    DashboardComponent,
    TutorUserSearchResultComponent,
    ModalGroupLessonFinish,
    ModalIEWarningComponent,
    ModalInstantRequest,
    ModalInstantRequestCancel,
    ModalMessagesMembers,
    ModalMessagesFiles,
    ModalLessonFinish,
    ModalLessonFinished,
    ModalLessonCancel,
    ModalLessonDone,
    ModalLessonRating,
    ModalSimpleError,
    ModalLessonMediaAccess,
    ModalLessonMediaConstraints,
    ModalReclamation,
    ModalBook,
    ModalBankcards,
    BookCheckoutComponent,
    ModalBankcardsRemove,
    FeedbackComponent,
    BankCardWarningDialogComponent,
    ModalLogoutConfirm,
    ProfileWarningComponent,
    FinanceHistoryComponent,
    FinanceGeneralComponent,
    AdvertisementComponent,
    AdvertisementWizardComponent,
    ModalUnavailableCamera,
    ModalLessonMediaInUse,
    ModalScreenSharingAccess,
    ModalRequestMediaStateChange,
    ModalDisableMediaByModerator,
    GroupLessonEmbeddedWindowComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    InfiniteScrollModule,
    ApiModule.forRoot({
      rootUrl: environment.apiUrl,
    }),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.authUrl, environment.apiUrl],
        sendAccessToken: true,
      },
    }),
    IconSpriteModule.forRoot({ path: environment.spritePath }),
    CoreModule,
    SharedModule,
    NgxMaskModule.forRoot(),
    TutorialModule,
    CalendarModule,
    TuiRootModule,
    TuiAxesModule,
    TuiScrollbarModule,
    TuiBarModule,
    TuiBarChartModule,
    GroupLessonMergeModule,
    MyLessonsModule,
    PopupModule,
    ButtonModule,
    DragDropModule,
    ProfileMenuModule,
    ProlongModule,
    NotificationsModule,
    LessonItemModule,
    TranslocoRootModule,
    LangSwitcherModule,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: TranslateDateAdapter,
    },
    UrlHelperService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: LOCALE_ID,
      deps: [LangStoreService],
      useFactory: (langStoreService: LangStoreService) => langStoreService.getCurrentLang(),
    },
    BrowserService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: OAuthStorage,
      useValue: localStorage,
    },
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(c: ConsoleLogSpyService) { }
}
