import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { RequestsService } from "@ezteach/_services/requests.service";
import { ChatRequest } from '@ezteach/api/models';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from '../../_services/local-storage.service';
import { LessonsService } from '../../api/services/lessons.service';

@Component({
  selector: 'requests-student-item',
  templateUrl: './requests-student-item.component.html',
})
export class RequestsStudentItemComponent implements OnInit {
  @Input() request: ChatRequest;
  countdown = '';
  value = 100;
  environment;
  timeoutInterval: NodeJS.Timeout;

  constructor(
    private _cdr: ChangeDetectorRef,
    private lessonsService: LessonsService,
    private localStorageService: LocalStorageService,
    private readonly requestsService: RequestsService
  ) { }

  ngOnInit() {
    this.environment = environment;

    const countDownDate = new Date(this.request.createDate as string).getTime();

    const now = new Date().getTime();
    const distance = countDownDate + this.request.expiresIn * 1000 - now;
    const hours = Math.floor((distance / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((distance / 1000 / 60) % 60);
    const seconds = Math.floor((distance / 1000) % 60);

    this.value = Math.round((distance / 1000 / this.request.expiresIn) * 100);

    if (distance < 0) {
      this.countdown = '';
    } else {
      this.countdown = ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2);
    }

    this._cdr.detectChanges();

    this.timeoutInterval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate + this.request.expiresIn * 1000 - now;
      const hours = Math.floor((distance / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((distance / 1000 / 60) % 60);
      const seconds = Math.floor((distance / 1000) % 60);

      this.value = (distance / 1000 / this.request.expiresIn) * 100;

      if (distance < 0) {
        this.countdown = '';
      } else {
        this.countdown = ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2);
      }

      if (distance <= 0) {
        clearInterval(this.timeoutInterval);
        this.requestsService.onChatRequestCanceled(this.request);
      }

      this._cdr.detectChanges();
    }, 100);
  }

  image(request) {
    if (request.callee.avatarFileName) {
      return (
        environment.apiUrl +
        '/api/v1/files/usercontent/' +
        request.callee.id +
        '/user-avatar?redirect=true&file=' +
        request.callee.avatarFileName
      );
    } else {
      return null;
    }
  }

  initials(request) {
    return request.callee.firstName.charAt(0).toUpperCase() + request.callee.lastName.charAt(0).toUpperCase();
  }

  cancelRequest(item) {
    this.requestsService.sending$.next(false);
    this.lessonsService
      .apiV1LessonsInstantRequestsRequestIdCancelPatch({
        requestId: item.id,
      })
      .subscribe(() => {
        this.localStorageService.remove('requests');
      });
  }
}
