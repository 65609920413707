import { DOCUMENT } from '@angular/common';
import { ElementRef, Inject, Renderer2 } from '@angular/core';
import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { Shape } from 'konva/lib/Shape';
import { BehaviorSubject, Subject, Subscription, fromEvent } from 'rxjs';
import {
  buffer,
  bufferTime,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { BackLayerRender } from './backlayer/backlayer-render';
import { ImagePreviewData, UploadedFile } from './classes/objects-args';
import { HeightOffsetBase, ImageShapeMeta, ShapeTypes, WhiteBoardMode, WidthOffSetBase } from './classes/white-board';
import { EraserFollower } from './components/tools/eraserFollower';
import { SelectedTool } from './components/tools/tools.component';
import { WhiteBoardConvertors } from './convertors/shape.convertors';
import { WhiteboardMenuService } from './services/whiteboard-menu.service';
import { WhiteboardStoreService } from './services/whiteboard-store.service';
import { MemberMouseManager } from './shapes/member-mouse-manager';
import { ShapeManager } from './shapes/shape-manager';
import { MOUSE_MIDDLE_BUTTON, MOUSE_RIGHT_BUTTON } from './utils/constants';
import { Coordinate } from './utils/floating-overlay-position';
import {
  WhiteBoardEditShapedState,
  WhiteBoardEraserState,
  WhiteBoardMouseState,
  WhiteBoardMovedState,
  WhiteBoardReadOnlyState,
  WhiteBoardShapedState,
  WhiteBoardState,
} from './whiteboard-state';
import { MemoShaper } from './whiteboardhistory/memo-shaper';

export interface ITouch {
  x: number,
  y: number
}

export class WhiteBoard {
  public whiteBoardState: WhiteBoardState;
  public stage: Konva.Stage;
  public backLayer: Konva.Layer;
  private mainLayer: Konva.Layer;
  private mouseLayer: Konva.Layer;
  private readonly canvasElement: HTMLDivElement;
  private readonlyState: boolean = false;

  public readonly shapeManager: ShapeManager;
  public backLayerRender: BackLayerRender;
  public memberMouseManager: MemberMouseManager;

  public width: number;
  public height: number;
  public offsetX: number = 0;
  public offsetY: number = 0;
  public scaleOffset: number = 1;
  startX = 0;
  startY = 0;

  onToolChanged$: Subject<SelectedTool> = new Subject<SelectedTool>();
  onMousePositionChanged$: Subject<any> = new Subject<any>();

  onObjectsCreated$: Subject<Shape | Konva.Group> = new Subject<Shape | Konva.Group>();
  onObjectsDeleted$: Subject<any> = new Subject<any>();
  onObjectsLocked$: Subject<any> = new Subject<any>();
  onObjectsUnLocked$: Subject<any> = new Subject<any>();
  onObjectsUpdated$ = new Subject<any[]>();
  shapeTauched$ = new Subject<boolean>();
  readonly loadPreview$ = new Subject<ImageShapeMeta>();
  wId: string;
  currentUserId: number;
  subscriptions: Subscription = new Subscription();
  scaleChange$ = new Subject();
  private readonly mouseDown$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private eraserFollower: EraserFollower;
  whiteBoardMode: WhiteBoardMode = WhiteBoardMode.self;
  isOwner = true;
  public mouseCooridnate: Coordinate = { x: 0, y: 0 };
  undoDisabled: boolean;
  redoDisabled: boolean;
  private fromTouchStart = false;
  private readonly doubleTap$: Subject<boolean> = new Subject();
  readonly click$: Subject<void> = new Subject<void>()
  public zoomIncreasing$: Subject<{ touch1: ITouch, touch2: ITouch }> = new Subject<{ touch1: ITouch, touch2: ITouch }>();

  constructor(
    private whiteboardRef: ElementRef,
    @Inject(DOCUMENT) private readonly documentRef: Document,
    readonly renderer: Renderer2,
    readonly whiteboardStoreService: WhiteboardStoreService,
    readonly whiteboardMenuService: WhiteboardMenuService,
    whiteBoardState: WhiteBoardState,
  ) {
    this.setState(whiteBoardState);
    this.canvasElement = documentRef.getElementById('ez-canvas') as HTMLDivElement;
    this.canvasElement.addEventListener(
      'contextmenu',
      e => {
        e.preventDefault();
      },
      false,
    );
    this.shapeManager = new ShapeManager(whiteboardStoreService);
    this.shapeManager.setWhiteBoard(this);
    this.memberMouseManager = new MemberMouseManager(this);
    const rect = this.canvasElement.getBoundingClientRect();
    this.startX = rect.x;
    this.startY = rect.y;
    Konva.hitOnDragEnabled = true;
    Konva.capturePointerEventsEnabled = true;
  }

  public stageGet(): any {
    return this.stage;
  }

  public mainLayerGet(): Konva.Layer {
    return this.mainLayer;
  }

  public mouseLayerGet(): Konva.Layer {
    return this.mouseLayer;
  }

  public setEraserFollower(eraserFollower: EraserFollower) {
    this.eraserFollower = eraserFollower;
  }

  public getEraserFollower() {
    return this.eraserFollower;
  }

  clearTransformOnMobile(target: any) {
    if (!(target.parent instanceof Konva.Transformer)) {
      this.clearTransform();
    }
  }

  targetId: number;

  private isSameShape(e): boolean {
    return e.target !== e.currentTarget
      && !!this.shapeManager.selectedShapes$.value?.length
  }

  initStage(width: number, height: number) {
    this.subscriptions.add(this.doubleTap$);

    this.doubleTap$
      .pipe(
        buffer(this.doubleTap$.pipe(throttleTime(300))),
        filter(tapArray => tapArray.length > 1),
        tap(_ => this.shapeManager.editTextTransformShape()),
      )
      .subscribe();

    this.width = width;
    this.height = height;
    this.stage = new Konva.Stage({
      width: width,
      height: height,
      container: this.canvasElement,
    });
    this.stage.on('click', (e: KonvaEventObject<MouseEvent>) => {
      if (!this.shapeManager.transform?.nodes().length) {
        this.shapeManager.selectedShapes$.next(null);
      }
      const { x, y } = e.evt;
      if (
        e.evt.button === MOUSE_RIGHT_BUTTON &&
        this.shapeManager.isSelectedShapeUnderCoordinate({
          x: this.whiteBoardState.withOffsetX(x),
          y: this.whiteBoardState.withOffsetY(y),
        })
      ) {
        return;
      }
      this.handleClick(e);
    });
    this.stage.on('touchstart ', e => {
      this.clearTransformOnMobile(e.target);
      if (this.isSameShape(e)) {
        this.doubleTap$.next(true);
        return;
      }
      this.fromTouchStart = true;
      e.evt.cancelBubble = true;
      this.whiteBoardState.handleTouchstart(e);
    });

    this.stage.on('touchend ', e => {
      if (!this.fromTouchStart) {
        return;
      }
      e.evt.preventDefault();
      this.whiteBoardState.handleTouchend(e);
      this.fromTouchStart = false;
    });
    this.stage.on('touchmove ', e => {
      if (!this.fromTouchStart) {
        return;
      }
      e.evt.preventDefault();
      if (e.evt.touches.length === 2) {
        const touch1 = this.getCoords(e.evt.touches[0])
        const touch2 = this.getCoords(e.evt.touches[1])
        this.zoomIncreasing$.next({ touch1, touch2 })
      } else {
        this.whiteBoardState.handleTouchmove(e)
      }
    });
    this.stage.on('dragend', e => {
      this.whiteBoardState.handleDragend(e);
    });
    this.stage.on('mousedown', e => {
      if (e.evt.button === MOUSE_MIDDLE_BUTTON) {
        e.evt.preventDefault();
        this.toMoveState();
        this.whiteBoardState.handleDragend(e);
      }
      this.handleMouseDown(e);
    });
    this.stage.on('mouseup', e => {
      this.handleMouseUp(e);
    });
    this.stage.on('mousemove', e => {
      this.whiteBoardState.handleMousemove(e);
    });

    this.stage.on('wheel touchmove', e => {
      e.evt.preventDefault();
      if (!(this.whiteBoardState instanceof WhiteBoardMovedState)) {
        this.toMoveState();
        return;
      }
      this.whiteBoardState.handleWheel(e);
    });

    this.stage.on('dblclick', e => { });

    this.stage.on('mouseleave', e => {
      if (this.mouseDown$.value) {
        this.handleMouseUp(e);
      }
    });

    // эмитация двойного клика, тк при клике на Konva.Transformer, стандартное событие не возникает
    const doubleClick$ = fromEvent(this.stage, 'click')
      .pipe(
        bufferTime(500),
        map(x => x.length),
        distinctUntilChanged(),
        filter(x => x == 2),
        tap(x => this.shapeManager.editTextTransformShape()),
      )
      .subscribe();
    this.subscriptions.add(doubleClick$);

    fromEvent(this.stage, 'mousemove')
      .pipe(
        bufferTime(200),
        mergeMap(x => x),
        debounceTime(1),
      )
      .subscribe((e: any) => {
        const x = this.whiteBoardState.withOffsetX(e.clientX);
        const y = this.whiteBoardState.withOffsetY(e.clientY);
        this.onMousePositionChanged$.next({ x: x, y: y });
        this.mouseCooridnate = { x, y };
      });

    this.stage.on('contextmenu', e => {
      e.evt.preventDefault();
      if (
        this.whiteBoardState instanceof WhiteBoardMouseState ||
        this.whiteBoardState instanceof WhiteBoardEditShapedState
      ) {
        const top = this.stage.getPointerPosition().y;
        const left = this.stage.getPointerPosition().x;
        this.whiteboardMenuService.open({ top, left });
      } else {
        this.whiteboardMenuService.close();
      }
    });

    const clickSubs = fromEvent(this.canvasElement, 'click')
      .pipe(
        tap(() => {
          this.hideContextMenu();
        }),
      )
      .subscribe();

    this.subscriptions.add(clickSubs);

    const keydownSubs = fromEvent(this.canvasElement, 'keydown')
      .pipe(
        map(x => x as KeyboardEvent),
        tap(x => {
          x.preventDefault();
          if (x.key.toUpperCase() === 'Shift'.toUpperCase()) {
            this.shapeManager.setShift(true);
          }
          if (x.ctrlKey || x.metaKey) {
            if (x.code === 'KeyC') this.shapeManager.copyShapes();
            if (x.code === 'KeyV') this.shapeManager.pasteShapes(this.mouseCooridnate);
          }
        }),
      )
      .subscribe();

    this.subscriptions.add(keydownSubs);

    const keyupSubs = fromEvent(this.canvasElement, 'keyup')
      .pipe(
        map(x => x as KeyboardEvent),
        tap(x => {
          x.preventDefault();
          if (x.key.toUpperCase() === 'Shift'.toUpperCase()) {
            this.shapeManager.setShift(false);
          }
          if (x.key.toUpperCase() === 'Delete'.toUpperCase()) {
            this.selectedShapes$().value.forEach(shape => {
              this.shapeManager.removeShape(shape);
            });
          }
        }),
      )
      .subscribe();

    this.subscriptions.add(keyupSubs);
  }

  getMemoShaper(): MemoShaper {
    return this.shapeManager.getMemoShaper();
  }

  hideContextMenu() {
    this.whiteboardMenuService.close();
  }

  getShapes$() {
    return this.shapeManager.shapes$;
  }

  selectedShapes$() {
    return this.shapeManager.selectedShapes$;
  }

  shapeDragStart$() {
    return this.shapeManager.shapeDragStart$;
  }

  shapeDragEnd$() {
    return this.shapeManager.shapeDragEnd$;
  }

  shapeTransformStart$() {
    return this.shapeManager.shapeTransformStart$;
  }

  shapeTransformEnd$() {
    return this.shapeManager.shapeTransformEnd$;
  }

  public getCanvasStartRectPosition() {
    const rect = this.canvasElement.getBoundingClientRect();
    return { x: rect.x, y: rect.y };
  }

  public setScale(zoom: number) {
    // вычисляем коэф масштаба
    const newScale = (zoom * 1) / 100;
    this.scaleOffset = newScale;
    // для того чтобы смещение доски было верное смещаем текущую позицию
    var oldScale = this.stage.scaleX() ? this.stage.scaleX() : 1;
    var oldScaleY = this.stage.scaleY() ? this.stage.scaleY() : 1;
    var pointer = this.stage.getPointerPosition();
    if (pointer == null) {
      pointer = { x: 0, y: 0 }
    }//Если по доске еще не кликали, то ее масштаб 1, а положение курсора 0
    var mousePointTo = {
      x: (pointer.x - this.stage.x()) / oldScale,
      y: (pointer.y - this.stage.y()) / oldScaleY,
    };

    this.stage.scale({ x: newScale, y: newScale });

    var newPos = {
      x: pointer.x - mousePointTo.x * newScale,
      y: pointer.y - mousePointTo.y * newScale,
    };

    this.stage.position(newPos);
    this.offsetX = -newPos.x;
    this.offsetY = -newPos.y;

    const position = this.stage.position();
    this.backLayerRender.updateGrid(this.width, this.height, position.x, position.y, this.scaleOffset);

    this.clearTransform();
  }

  private getCoords(touch): ITouch {
    return {
      x: touch.clientX,
      y: touch.clientY
    }
  }

  public setWidth(width: number) {
    this.stage.setAttr('width', width);
    this.backLayer.setAttr('width', width);
    this.mainLayer.setAttr('width', width);
    this.mouseLayer.setAttr('width', width);
  }

  public setHeight(height: number) {
    this.stage.setAttr('height', height);
    this.backLayer.setAttr('height', height);
    this.mainLayer.setAttr('height', height);
    this.mouseLayer.setAttr('height', height);
  }

  initBackLayer(width: number, height: number) {
    this.backLayer = new Konva.Layer();
    this.backLayer.listening(false);
    this.backLayerRender = new BackLayerRender(this.backLayer);
    this.backLayerRender.renderGrid(width, height);
    this.stage.add(this.backLayer);
  }

  initMainLayer(width: number, height: number) {
    this.mainLayer = new Konva.Layer();
    this.stage.add(this.mainLayer);
    this.mainLayer.draw();
    this.shapeManager.initTouchShaper();
  }

  initMouseLayer(width: number, height: number) {
    this.mouseLayer = new Konva.Layer();
    this.stage.add(this.mouseLayer);
    this.mouseLayer.draw();
  }

  clearAllCursor() {
    this.renderer.removeClass(this.documentRef.querySelector('#ez-canvas'), 'eraser-cursor');
    this.renderer.removeClass(this.documentRef.querySelector('#ez-canvas'), 'pen-cursor');
    this.renderer.removeClass(this.documentRef.querySelector('#ez-canvas'), 'mouse-cursor');
    this.renderer.removeClass(this.documentRef.querySelector('#ez-canvas'), 'shape-cursor');
    this.renderer.removeClass(this.documentRef.querySelector('#ez-canvas'), 'drag-cursor');
  }

  setState(whiteBoardState: WhiteBoardState, emitEvent?: boolean) {
    if (this.readonlyState) {
      return;
    }

    this.clearAllCursor();
    if (!(whiteBoardState instanceof WhiteBoardEraserState)) {
      this.shapeManager?.setEraserMode(false);
      this.eraserFollower?.disableListeners();
    }
    if (whiteBoardState instanceof WhiteBoardEraserState) {
      this.renderer.addClass(this.documentRef.querySelector('#ez-canvas'), 'eraser-cursor');
      this.eraserFollower?.enableListeners();
    }
    if (
      whiteBoardState instanceof WhiteBoardShapedState &&
      this.shapeManager.getCurrentShape() !== ShapeTypes.freeline
    ) {
      this.renderer.addClass(this.documentRef.querySelector('#ez-canvas'), 'shape-cursor');
    }
    if (
      whiteBoardState instanceof WhiteBoardShapedState &&
      this.shapeManager.getCurrentShape() === ShapeTypes.freeline
    ) {
      this.renderer.addClass(this.documentRef.querySelector('#ez-canvas'), 'pen-cursor');
    }
    if (whiteBoardState instanceof WhiteBoardMouseState) {
      this.renderer.addClass(this.documentRef.querySelector('#ez-canvas'), 'mouse-cursor');
    }

    if (whiteBoardState instanceof WhiteBoardMovedState) {
      this.renderer.addClass(this.documentRef.querySelector('#ez-canvas'), 'drag-cursor');
    }
    this.whiteBoardState = whiteBoardState;
    this.whiteBoardState.setWhiteBoard(this);
    if (emitEvent) {
      this.onToolChanged$.next(whiteBoardState.getTool());
    }
  }

  toMoveState() {
    this.stage.draggable(true);
    this.renderer.addClass(this.canvasElement, 'whiteboard-moved');
    this.setState(new WhiteBoardMovedState());
    this.shapeManager.toWhiteBoardMoveState();
  }

  toEraserState() {
    this.stage.draggable(false);
    this.setState(new WhiteBoardEraserState());
    this.clearTransform();
  }

  toReadOnlyState() {
    this.readonlyState = true;
    this.setState(new WhiteBoardReadOnlyState());
    this.shapeManager.toWhiteBoardReadonlyState();
  }

  toShapeState(shapeType: ShapeTypes) {
    this.stage.draggable(false);
    this.renderer.removeClass(this.canvasElement, 'whiteboard-moved');
    this.shapeManager.setCurrentShape(shapeType);
    this.setState(new WhiteBoardShapedState());
    this.shapeManager.toWhiteBoardShapeState();
  }

  toMouseState() {
    this.stage.draggable(false);
    this.renderer.removeClass(this.canvasElement, 'whiteboard-moved');
    this.setState(new WhiteBoardMouseState());
    this.shapeManager.toWhiteBoardReadonlyState();
  }

  clearTransform() {
    this.shapeManager.clearTransform();
  }

  moveBack() {
    this.shapeManager.moveBack();
  }

  moveFront() {
    this.shapeManager.moveFront();
  }

  handleClick(e) {
    this.whiteBoardState.handleClick(e);
    this.click$.next();
  }

  handleMouseDown(e) {
    if (this.whiteBoardState instanceof WhiteBoardEraserState) {
      this.eraserFollower?.enableEraserFollowerCanvas();
    }
    this.whiteBoardState.handleMousedown(e);
    this.mouseDown$.next(true);
  }
  handleMouseUp(e) {
    if (this.whiteBoardState instanceof WhiteBoardEraserState) {
      this.eraserFollower?.disableEraserFollowerCanvas();
    }
    this.whiteBoardState.handleMouseup(e);
    this.mouseDown$.next(false);
  }

  handleTouchstart(e) {
    this.whiteBoardState.handleTouchstart(e);
  }

  handleTouchend(e) {
    this.whiteBoardState.handleTouchend(e);
  }

  handleDragend() { }

  makeSnapshot() { }

  undo() {
    this.shapeManager.undo();
  }

  redo() {
    this.shapeManager.redo();
  }

  setUserMouse(data: any, zoom: number) {
    if (data && data.name) {
      this.memberMouseManager.setMemberMouse(data,zoom);
    }
  }

  removeUser(memberId: number) {
    this.memberMouseManager.removeMemberMouse(memberId);
  }

  disance(p1, p2) {
    return Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);
  }

  addShape(data: any) {
    const args = WhiteBoardConvertors.convertCreateObjectDescriptionToShape(data.objects[0]);
    this.shapeManager.addShapeOrQueue(args);
  }

  addFile(data: UploadedFile) {
    this.shapeManager.addFile(data);
  }

  addPreview(data: ImagePreviewData) {
    this.shapeManager.addPreview(data);
  }

  /*
   * Для событий touch не работает стандартный draggable Stage, считаем вручную
   */
  touchStartX = 0;
  touchStartY = 0;
  touchStartStage(e: TouchEvent) {
    this.touchStartX = this.offsetX + e.targetTouches[0].clientX;
    this.touchStartY = this.offsetY + e.targetTouches[0].clientY;
  }

  touchMoveStage(e: TouchEvent) {
    const newX = e.targetTouches[0].clientX;
    const newY = e.targetTouches[0].clientY;
    const diffX = newX - this.touchStartX;
    const diffY = newY - this.touchStartY;
    this.stage.position({ x: diffX, y: diffY });
  }

  wheelMoveStage(e: WheelEvent | TouchEvent) {
    const deltaX = (e as WheelEvent).deltaX ?? (e as TouchEvent).touches[0].pageX;
    const deltaY = (e as WheelEvent).deltaY ?? (e as TouchEvent).touches[0].pageY;

    this.startX = this.stage.position().x - deltaX;
    this.startY = this.stage.position().y - deltaY;

    this.offsetX -= deltaX;
    this.offsetY -= deltaY;

    this.stage.position({ x: this.startX, y: this.startY });
  }

  wheelScaleStage(e: WheelEvent) {
    this.scaleChange$.next(e.deltaY);
  }

  setWhiteBoardMode(mode: WhiteBoardMode) {
    this.whiteBoardMode = mode;
  }

  setIsOwner(isOwner: boolean) {
    this.isOwner = isOwner;
  }

  whiteBoardResize(): void {

    const newWidth = this.whiteboardRef.nativeElement.clientWidth;
    const newHeight = this.whiteboardRef.nativeElement.clientHeight;

    this.stage.width(newWidth - WidthOffSetBase);
    this.stage.height(newHeight - HeightOffsetBase);
    this.backLayerRender.renderGrid(
      newWidth,
      newHeight,
    );

    this.width = newWidth;
    this.height = newHeight;
  }

  destroy() {
    this.shapeManager.destroy();
    this.subscriptions.unsubscribe();
  }
}
