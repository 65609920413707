import { SnapshotActon } from '../whiteboardhistory/memo-shaper';
import { IShapeSnapShot, WhiteBoardShape } from './shape';

/*
 * Базовый класс состояние доски
 */
export abstract class WhiteBoardShapeState {
  protected shape: WhiteBoardShape;

  setShape(shape: WhiteBoardShape) {
    this.shape = shape;
  }

  public abstract handleClick(e): void;
  public abstract handleDoubleClick(e): void;
  public abstract handleReadonly(e): void;
  public abstract handleDragStart(e): void;
  public abstract handleDragEnd(e): void;
  public abstract handleDragMove(e): void;
  public abstract handleMouseup(e): void;
  public abstract handleMousedown(e): void;
  public abstract handleTransformstart(e): void;
  public abstract handleTransformend(e): void;
  public abstract handleTextFieldSelect(e): void;
}

export class WhiteBoardShapeReadonlyState extends WhiteBoardShapeState {
  public handleDragMove(e: any): void {}
  public handleTransformend(e: any): void {}
  public handleTransformstart(e: any): void {}
  public handleMousedown(e: any): void {}
  public handleMouseup(e: any): void {}
  public handleDragStart(e: any): void {}
  public handleClick(e: any): void {
    this.shape.getShapeManager().selectReadOnlyFileDownload(e);
  }
  public handleDoubleClick(e: any): void {}
  public handleReadonly(e: any): void {}
  public handleDragEnd(e: any): void {}

  public handleTextFieldSelect(e: any): void {}
}

export class WhiteBoardShapeMouseState extends WhiteBoardShapeState {
  public handleTransformend(e: any): void {
    if (this.shape.getShapeManager().canEditShape()) {
      const currentLockedId = this.shape.konvajsShape.getAttr('ezId');
      this.shape.getShapeManager().whiteBoard.onObjectsUnLocked$.next([currentLockedId]);
    }
    this.shape.getShapeManager().shapeDragEnd$.next(this.shape);
    this.shape.getShapeManager().addSnapshot(this.shape, this.snapshot, SnapshotActon.editShape);
    this.snapshot = null;
  }
  public handleTransformstart(e: any): void {
    if (this.shape.getShapeManager().canEditShape()) {
      const currentLockedId = this.shape.konvajsShape.getAttr('ezId');
      this.shape.getShapeManager().whiteBoard.onObjectsLocked$.next([currentLockedId]);
    }
    this.shape.getShapeManager().shapeTransformStart$.next(this.shape);
    e.cancelBubble = true;
    this.snapshot = this.shape.createHistorySnapshot();
  }
  public handleMousedown(e: any): void {
    e.cancelBubble = true;
  }
  public handleMouseup(e: any): void {
    e.cancelBubble = true;
  }

  public handleClick(e: any): void {
    e.cancelBubble = true;
  }
  public handleDoubleClick(e: any): void {}
  public handleReadonly(e: any): void {}
  snapshot: IShapeSnapShot;
  public handleDragStart(e: any): void {
    if (this.shape.getShapeManager().canEditShape()) {
      const currentLockedId = this.shape.konvajsShape.getAttr('ezId');
      this.shape.getShapeManager().whiteBoard.onObjectsLocked$.next([currentLockedId]);
    }
    this.shape.getShapeManager().shapeDragStart$.next(this.shape);
    this.snapshot = this.shape.createHistorySnapshot();
  }
  public handleDragEnd(e: any): void {
    if (this.shape.getShapeManager().canEditShape()) {
      const currentLockedId = this.shape.konvajsShape.getAttr('ezId');
      this.shape.getShapeManager().whiteBoard.onObjectsUnLocked$.next([currentLockedId]);
    }
    this.shape.getShapeManager().shapeDragEnd$.next(this.shape);
    this.shape.getShapeManager().addSnapshot(this.shape, this.snapshot, SnapshotActon.editShape);
    this.snapshot = null;
  }
  public handleTextFieldSelect(e: any): void {
    this.shape.getShapeManager().editTextProperties(null);
  }
  public handleDragMove(e: any): void {
    if (this.shape.shouldCancelEvents) {
      this.shape.konvajsShape.stopDrag();
    }
  }
}

export class WhiteBoardMoveShapeState extends WhiteBoardShapeState {
  public handleDragMove(e: any): void {}
  public handleTransformend(e: any): void {}
  public handleTransformstart(e: any): void {}
  public handleMousedown(e: any): void {
    e.cancelBubble = true;
  }
  public handleMouseup(e: any): void {
    e.cancelBubble = true;
  }
  public handleDragStart(e: any): void {}
  public handleClick(e: any): void {
    e.cancelBubble = true;
  }
  public handleDoubleClick(e: any): void {}
  public handleReadonly(e: any): void {}
  public handleDragEnd(e: any): void {
    console.log(e);
  }
  public handleTextFieldSelect(e: any): void {}
}

export class WhiteBoardLockedShapeState extends WhiteBoardShapeState {
  public handleDragMove(e: any): void {}
  public handleClick(e: any): void {}
  public handleDoubleClick(e: any): void {}
  public handleReadonly(e: any): void {}
  public handleDragStart(e: any): void {}
  public handleDragEnd(e: any): void {}
  public handleMouseup(e: any): void {}
  public handleMousedown(e: any): void {}
  public handleTransformstart(e: any): void {}
  public handleTransformend(e: any): void {}
  public handleTextFieldSelect(e: any): void {}
}

export class WhiteBoardShapeTouchState extends WhiteBoardShapeState {
  public handleDragMove(e: any): void {}
  public handleTransformend(e: any): void {
    //this.shape.getShapeManager().addSnapshot(this.shape, this.snapshot, SnapshotActon.editShape);
    //this.snapshot = null;
  }
  public handleTransformstart(e: any): void {
    //e.cancelBubble = true;
    //this.snapshot = this.shape.createHistorySnapshot();
  }
  public handleMousedown(e: any): void {
    e.cancelBubble = true;
  }
  public handleMouseup(e: any): void {
    e.cancelBubble = true;
  }

  public handleClick(e: any): void {
    e.cancelBubble = true;
  }
  public handleDoubleClick(e: any): void {}
  public handleReadonly(e: any): void {}
  snapshot: IShapeSnapShot;
  public handleDragStart(e: any): void {
    this.snapshot = this.shape.createHistorySnapshot();
  }
  public handleDragEnd(e: any): void {
    this.shape.getShapeManager().addSnapshot(this.shape, this.snapshot, SnapshotActon.editShape);
    this.snapshot = null;
  }
  public handleTextFieldSelect(e: any): void {}
}
